$color-red: #ce0e2d;
$color-38: #383838;
$color-light-black: #4c4d4b;
$color-blue: #556fb6;
$color-20: #202020;
$color-white: #fff;
$color-f8: #f8f8f8;
$color-e8: #e8e8e8;
$color-b1: #b1b1b1;
$color-b7 : #b7b7b7;
$color-00: #000;
$color-light-sea-green :#dbf4f2;
$color-teal: #008380;
$color-30 : #303030;
$color-fa: #fafafa;
$color-f4: #f4f4f4;
$color-d9: #d9d9d9;

:root {
    --color-red: #ce0e2d;
    --color-dark-gray: #383838;
    --color-light-black: #4c4d4b;
    --color-blue: #055989;
    --color-medium-black: #202020;
    --color-white: #fff;
    --color-light-gray: #f8f8f8;
    --color-medium-gray: #e8e8e8;
    --color-gray: #b1b1b1;
    --color-gray-b7: #b7b7b7;
    --color-dark-back: #000;
}