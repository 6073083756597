// Variable overrides first
$primary: #ce0e2d;
$enable-shadows: true;
$prefix: "mx-";
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap";
@import './styles/colors';
@import './styles/buttons';
@import './styles/typeface';
@import './styles/core';

body {
    min-height: 100vh;
    position: relative;
    font-weight: 400;
    font-family: proxima-nova, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: none;
    line-height: 1.57;
    background: $color-f8;
}

html,
body {
    height: 100%;
}