@import "./mixins/box-shadow.mixin";
@import "./mixins/breakpoints";

// Common Config
.cursor-pointer {
    cursor: pointer;
}

ul,
ol {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.form-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
}

// Font weights
.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

// Color Classes
.color-red {
    color: $color-red;
}

.color-white {
    color: $color-white;
}

.color-dark-gray {
    color: $color-38;
}

// Footer 
.footer-section {
    width: 100%;
    background: $color-20;
    padding: 30px 0;
    color: #fff;

    .copy-right-text {
        font-size: 20px;
    }

    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @include breakpoint-range($max : lg) {
            flex-direction: column-reverse;
            align-items: flex-start;

            .footer-menu {
                margin-top: 20px;
                flex-wrap: wrap;

                &>li {
                    margin-bottom: 10px;
                }
            }
        }
    }

}

.cla-accordion {
    border-radius: 0 0 5px 5px;
    overflow: hidden;

    .panel {
        border: none;
        margin-bottom: 15px;

        .panel-heading {
            padding: 0;
            border: none;
            border-radius: 8px;
            border: 1px solid #e5e5e5;

            .panel-title {
                .btn {
                    padding: 0;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    height: auto;
                    color: $color-38;
                    text-decoration: none;
                    font-size: 20px;
                    font-weight: 600;
                    padding: 15px 22px;
                    background: $color-white;
                    margin: 0;
                    text-transform: capitalize;
                    border-radius: 8px;


                    &::after {
                        background: url('/assets/icons/down-arrow.svg') no-repeat;
                        width: 22px;
                        height: 12px;
                        content: '';
                        position: relative;
                        top: 10px;
                    }
                }
            }
        }

        .panel-body {
            border: 1px solid $color-blue;
            border-top: none;
            border-radius: 0 0 8px 8px;
            padding: 15px 22px;
        }

        &.panel-open {
            .panel-heading {
                border-radius: 8px 8px 0 0;
                border-bottom: 0;
                border-color: $color-blue;

                .panel-title {
                    .btn {
                        border-radius: 8px 8px 0 0;
                        position: relative;

                        &::before {
                            content: '';
                            width: calc(100% - 44px);
                            left: 22px;
                            border-bottom: 1px solid #e5e5e5;
                            bottom: 0;
                            position: absolute;
                        }


                        &::after {
                            transform: rotate(-180deg);
                            transition: all;
                        }
                    }
                }
            }
        }
    }
}